<template>
  <div :class="$style.wrapper">
    <Logo :class="$style.logo" />

    <div :class="$style.content">
      <Navigation :links="links" />

      <div :class="$style.aside">
        <Link :link="SHOWCASE_ROUTE_PROFILE_BUILDER" :child-class="$style.link">
          <Button :class="$style.button">{{ $i18n('Je commande') }}</Button>
        </Link>

        <Link :link="memberAreaLink" :child-class="$style.link">
          <Icon name="user" :class="$style.userIcon" />
        </Link>
      </div>
    </div>
  </div>
</template>

<script>
import { Logo } from '@front/common/components'
import { Button, Icon } from '@front/ui'
import { computed, defineComponent } from '@vue/composition-api'

import Link from '@showcase/components/Link.vue'
import { SHOWCASE_ROUTE_PROFILE_BUILDER } from '@showcase/core/routes-names'

import Navigation from './components/Navigation.vue'

export default defineComponent({
  components: {
    Logo,
    Navigation,
    Button,
    Icon,
    Link,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const memberAreaLink = computed(() => {
      return process.env.MEMBER_AREA_URL
    })

    return {
      SHOWCASE_ROUTE_PROFILE_BUILDER,
      memberAreaLink,
    }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  align-items: center;
  padding: $ds-spacing-mobile-small 0px;
}

.logo {
  width: 116px;
  height: 58px;
  margin-right: $ds-spacing-mobile-small;
  margin-top: -10px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.aside {
  display: flex;
  align-items: center;
  gap: $ds-spacing-mobile-small;
}

.link {
  display: flex;
  color: black;
}

.userIcon {
  height: 28px;
  width: 28px;
  stroke-width: 1.5;
  border: 1.5px solid #000;
  border-radius: 50%;
}
</style>
